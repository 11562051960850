import "./KeezSpeaker.css"
import Lottie from "lottie-react";
import AI from "../assets/AI.json";
import {useEffect, useState} from "react";
import { useSpring, animated } from '@react-spring/web'

const KeezSpeaker = ({ texts, onFinished }) => {
    const [currentText, setCurrentText] = useState(null);
    const [index, setIndex] = useState(0)

    const [opacityText, api] = useSpring(() => ({
        from: { opacity: 0 },
    }))

    const [lottieStyle, apiLottie] = useSpring(() => ({
        from: { opacity: 0 },
    }))

    useEffect(() => {
        if (texts && texts.length > 0) {
            setCurrentText(texts[0])
            setIndex(0)
            animate(1)
        }
        else {
            handleFinished()
        }

        return () => {
            setIndex(0)
            setCurrentText(texts[0])
        }
    }, [])

    useEffect(() => {
        if (index > 0) {
            setCurrentText(texts[index])
            animate(1)
        }
        else {
            apiLottie.start({
                to: {
                    opacity: 1
                },
                config: { duration: 1000 },
            })
        }
    }, [index])

    const animate = () => {
        api.start({
            to: {
                opacity: 1
            },
            config: { duration: 1000 },
            onResolve: () => {
                setTimeout(() => {
                    if (index === texts.length - 1) {
                        api.start({
                            to: {
                                opacity: 0
                            },
                            config: { duration: 1000 },
                        })
                        handleFinished()
                    }
                    else {
                        api.start({
                            to: {
                                opacity: 0
                            },
                            config: { duration: 1000 },
                            onResolve: () => {
                                setIndex(index + 1)
                            }
                        })
                    }
                }, 1000)
            }
        })
    }

    const handleFinished = () => {
        apiLottie.start({
            to: {
                opacity: 0
            },
            config: { duration: 1000 },
            onResolve: () => {
                onFinished()
            }
        })
    }

    return (
        <div className="speaker-container">
            <animated.div style={{height: '50vh', ...opacityText}}>
                <p className="ai-text" style={{fontFamily: "Sequel Bold", color: "#2E3B3C"}}>{currentText}</p>
            </animated.div>
            <animated.div style={{display: "flex", height: '15vh', ...lottieStyle}}>
                <Lottie
                    animationData={AI} loop={true}
                    style={{display: "flex", height: '100%'}}
                />
            </animated.div>
        </div>
    )
}

export default KeezSpeaker;
