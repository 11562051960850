import "./DateInput.css"
import {useEffect} from "react";

const DateInput = ({ value, setValue, placeholder, style = {}, isError = false }) => {

    useEffect(() => {
        const date = new Date().toISOString().split('T')[0];
        document.getElementById('datePicker').valueAsDate = new Date(date);
        setValue(date)
    }, [])

    return (
        <input
            id="datePicker"
            className="date-input"
            type="date"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{
                width: '100%', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0, borderRadius: 0,
                fontFamily: "Sequel", fontSize: 24, borderBottomWidth: 1, borderColor: isError ? 'red' : '#9FA4A4',
                paddingTop: 15, paddingBottom: 15, paddingLeft: 25, paddingRight: 25, marginTop: 15, marginBottom: 15,
                backgroundColor: 'transparent', color: 'black', borderStyle: 'solid', ...style
        }}
        />
    )
}

export default DateInput;
