import "./List.css"
import {getGenderString, getSearchGenderString} from "../api";

const DateInput = ({ list, onItemPress, selectedItem, isGender = false, isSearchGender = false }) => {

    const isSelected = (item) => {
        if (typeof item === 'string') {
            return item === selectedItem
        }
        else if (selectedItem) {
            return item.text === selectedItem.text
        }

        return false
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 0,
            marginBottom: 15
        }}>
            {list.map((item, index) => {
                const selected = isSelected(item)
                return (
                    <div
                        className="item"
                        key={index} onClick={() => onItemPress(item)}
                        style={{
                            width: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            paddingTop: 15, paddingBottom: 15, paddingLeft: 30, paddingRight: 30,
                            borderRadius: 20, marginTop: 4, marginBottom: 4,
                            color: selected ? '#734DC2' : '#9FA4A4',
                            textAlign: 'left',
                            fontFamily: "Sequel Bold", borderStyle: 'solid', borderWidth: 1, borderColor: selected ? '#734DC2' : "#E8DDFC"
                    }}
                    >
                        {typeof item === 'string' ? (isGender ? getGenderString(item) : (isSearchGender ? getSearchGenderString(item) : item)) : item.text}
                    </div>
                )
            })}
        </div>
    )
}

export default DateInput;
