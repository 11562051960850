export const computeAttachmentStyle = (user, setAttachmentStyle, setExperienceScore, setConscientiousnessScore, setExtroversionScore, setAgreeablenessScore, setNeuroticismScore) => {
    if (user && user.answers) {
        const answers = user.answers
        let secure = 0
        let ambivalent = 0
        let avoiding = 0
        let disorganized = 0
        let experience = 0
        let conscientiousness = 0
        let extroversion = 0
        let agreeableness = 0
        let neuroticism = 0
        answers.forEach(answer => {
            const scoreType = answer.score
            if (scoreType === 'SECURE') {
                secure++
            }
            else if (scoreType === 'AMBIVALENT') {
                ambivalent++
            }
            else if (scoreType === 'AVOIDING') {
                avoiding++
            }
            else if (scoreType === 'DISORGANIZED') {
                disorganized++
            }
            else if (scoreType) {
                const score = parseInt(scoreType)
                const type = answer.type ? answer.type.toLowerCase() : null
                if (type) {
                    if (type === 'experience') {
                        experience += score
                    }
                    else if (type === 'conscientiousness') {
                        conscientiousness += score
                    }
                    else if (type === 'extroversion') {
                        extroversion += score
                    }
                    else if (type === 'agreeableness') {
                        agreeableness += score
                    }
                    else if (type === 'neuroticism') {
                        neuroticism += score
                    }
                }
            }
        })
        setExperienceScore(experience)
        setConscientiousnessScore(conscientiousness)
        setExtroversionScore(extroversion)
        setAgreeablenessScore(agreeableness)
        setNeuroticismScore(neuroticism)
        //find the max
        const max = Math.max(secure, ambivalent, avoiding, disorganized)
        if (max === secure) {
            setAttachmentStyle('SECURE')
        }
        else if (max === ambivalent) {
            setAttachmentStyle('AMBIVALENT')
        }
        else if (max === avoiding) {
            setAttachmentStyle('AVOIDING')
        }
        else if (max === disorganized) {
            setAttachmentStyle('DISORGANIZED')
        }
    }
}


export const getAttachmentText = (attachmentStyle) => {
    switch (attachmentStyle) {
        case "SECURE":
            return "Félicitations ! Tu as un style d'attachement Sécurisant, ce qui te permet de maintenir un excellent équilibre entre intimité et indépendance. Tu construis des relations stables et harmonieuses, grâce à ta capacité à faire confiance et à communiquer ouvertement. Tu réponds de manière empathique aux besoins émotionnels de tes partenaires, ce qui favorise des liens profonds et durables. Ta manière de gérer les relations est un véritable atout pour des interactions saines et épanouissantes."
        case "AMBIVALENT":
            return "Tu as un style d'attachement Ambivalent/Résistant qui témoigne d'une grande sensibilité et d'un profond désir de connexion. Même si tu peux te sentir parfois anxieux(se) concernant la solidité de tes relations, cette sensibilité te rend particulièrement attentif(aux) aux besoins et aux sentiments de tes partenaires. En embrassant cette partie de toi et en renforçant ta confiance en soi, tu peux développer des relations encore plus stables et équilibrées."
        case "AVOIDING":
            return "Tu as un style d'attachement Évitant qui souligne une forte appréciation de l'indépendance, ce qui est une qualité remarquable. Bien que tu puisses parfois hésiter à te rapprocher émotionnellement, cette autonomie te permet de maintenir un espace personnel sain. En explorant de nouvelles façons de partager tes émotions, tu ouvriras la porte à des relations encore plus enrichissantes et significatives."
        default:
            return "Tu as un style d'attachement Désorganisé, ce qui reflète une capacité unique à naviguer entre l'intimité et la distance, même si cela peut parfois sembler contradictoire. Cette flexibilité émotionnelle est le reflet de tes expériences de vie diversifiées. En reconnaissant et en acceptant ce schéma, et potentiellement en cherchant un soutien thérapeutique, tu pourras trouver des stratégies pour clarifier tes relations et renforcer ta sécurité émotionnelle, ce qui te mènera vers des interactions plus sereines et assurées."
    }
}

export const getPersonalityText = (sign) => {
    switch (sign) {
        case "aries":
            return "Avec une tendance naturelle à prendre les devants, tu cherches une relation où la passion et l'aventure ne font jamais défaut. Ton idéal amoureux est quelqu'un qui peut non seulement suivre ton rythme mais aussi partager ton enthousiasme pour la vie."
        case "taurus":
            return "Ton approche de l'amour est douce et sensuelle, cherchant toujours la stabilité et la fidélité. Tu es attiré par ceux qui savent créer un environnement harmonieux et chaleureux, où les sentiments sont profonds et les engagements sérieux."
        case "gemini":
            return "Communicatif et vif, tu es attiré par des relations qui stimulent ton intellect et ton besoin de variété. Tu es séduit par ceux qui peuvent converser de tout et de rien, rendant chaque jour nouveau et excitant."
        case "cancer":
            return "Pour toi, l'amour doit être sécurisant et nourrissant, offrant un refuge contre le tumulte du monde extérieur. Tu valorises la loyauté et le soin mutuel, et tu es le plus heureux avec quelqu'un qui comprend ton besoin de racines profondes et d'intimité."
        case "leo":
            return "Ton esprit libre et aventurier te pousse vers des relations où l'indépendance et la liberté sont respectées. Tu es le plus compatible avec ceux qui respectent ton espace tout en t'offrant leur cœur sans conditions."
        case "virgo":
            return "Perfectionniste en amour, tu cherches quelqu'un qui non seulement répond à tes standards élevés mais qui s'efforce également d'améliorer votre relation jour après jour. La critique constructive et le désir de perfectionnement mutuel sont les clés de ton cœur."
        case "libra":
            return "Intuitif et profond, tu recherches une connexion qui transcende les conversations superficielles pour plonger dans les mystères de l'univers. Ton partenaire idéal est quelqu'un qui peut explorer avec toi les profondeurs de l'existence et partager une quête spirituelle."
        case "scorpio":
            return "Passionné et intense, ton approche de l'amour peut être tout ou rien. Tu es attiré par des partenaires qui sont aussi passionnés et expressifs que toi, et qui ne reculent pas devant la profondeur de tes émotions."
        case "sagittarius":
            return "Optimiste et toujours prêt à voir le meilleur chez les autres, tu cherches un partenaire qui partage ton désir d'aventure et ton besoin d'expansion. Les relations qui encouragent la croissance mutuelle et les découvertes sont celles qui te captivent le plus."
        case "capricorn":
            return "Prudent et sérieux en amour, tu prends ton temps pour t'ouvrir vraiment, cherchant une garantie que ton cœur sera en sécurité. Un partenaire qui comprend et respecte ton besoin de prudence peut débloquer les portes de ton affection."
        case "aquarius":
            return "Innovateur et avant-gardiste, tu es attiré par ceux qui brisent les moules et défient les conventions. Un partenariat qui défie les normes traditionnelles et explore de nouvelles façons de vivre l'amour t'attire profondément."
        default:
            return "Empathique et dévoué, tu donnes tout en amour, souvent au point de mettre les besoins des autres avant les tiens. Un partenaire qui reconnaît et valorise ton altruisme peut te donner l'équilibre nécessaire pour que la relation soit nourrissante des deux côtés."
    }
}

export const getBigFiveText = (experienceScore, conscientiousnessScore, extroversionScore, agreeablenessScore, neuroticismScore) => {
    console.log('experienceScore = ', experienceScore, 'conscientiousnessScore = ', conscientiousnessScore, 'extroversionScore = ', extroversionScore, 'agreeablenessScore = ', agreeablenessScore, 'neuroticismScore = ', neuroticismScore)
    let experienceText = ""
    const experienceTotal = 10
    let section = experienceTotal / 4
    if (experienceScore <= section) {
        experienceText = "Tu tends à apprécier la routine et la prévisibilité. Les nouvelles expériences peuvent te sembler moins attrayantes, car tu trouves ton confort dans ce qui est familier."
    }
    else if (experienceScore <= section * 2) {
        experienceText = "Tu es ouvert(e) à de nouvelles expériences dans une certaine mesure, prêt(e) à explorer de nouvelles idées tout en gardant un lien avec ce qui est éprouvé."
    }
    else if (experienceScore <= section * 3) {
        experienceText = "Tu es naturellement curieux(se) et ouvert(e) à de nouvelles idées et expériences. Tu te lances volontiers dans l'exploration de l'inconnu, cherchant à élargir tes horizons."
    }
    else {
        experienceText = "Ton désir de découverte est presque insatiable. Tu es constamment à la recherche de nouveautés et d'expériences qui peuvent transformer ta perception du monde."
    }

    let conscientiousnessText = ""
    const conscientiousnessTotal = 10
    section = conscientiousnessTotal / 4
    if (conscientiousnessScore <= section) {
        conscientiousnessText = "Tu préfères la spontanéité à la planification rigide et tu pourrais trouver les détails et la routine ennuyeux ou contraignants."
    }
    else if (conscientiousnessScore <= section * 2) {
        conscientiousnessText = "Tu préfères la spontanéité à la planification rigide et tu pourrais trouver les détails et la routine ennuyeux ou contraignants."
    }
    else if (conscientiousnessScore <= section * 3) {
        conscientiousnessText = "Tu es méthodique, bien organisé(e) et fiable. Tu prends tes responsabilités au sérieux et tu t'efforces souvent d'atteindre l'excellence dans tes tâches."
    }
    else {
        conscientiousnessText = "Tu es extrêmement diligent(e) et prêt(e) à investir beaucoup d'efforts pour maintenir l'ordre et la précision. Ta discipline et ton attention aux détails sont exemplaires."
    }

    let extroversionText = ""
    const extroversionTotal = 10
    section = extroversionTotal / 4
    if (extroversionScore <= section) {
        extroversionText = "Tu es introverti(e) et préfères souvent passer du temps seul(e), trouvant les grands groupes et les interactions sociales épuisantes."
    }
    else if (extroversionScore <= section * 2) {
        extroversionText = "Tu apprécies les interactions sociales, mais tu as aussi besoin de temps seul(e) pour te recharger. Tu balances entre l'engagement social et l'indépendance."
    }
    else if (extroversionScore <= section * 3) {
        extroversionText = "Tu apprécies les interactions sociales, mais tu as aussi besoin de temps seul(e) pour te recharger. Tu balances entre l'engagement social et l'indépendance."
    }
    else {
        extroversionText = "Tu es vraiment extraverti(e), avec une présence dynamique qui attire les autres. Ta vie sociale est très active et tu te sens à l'aise dans presque toutes les situations sociales."
    }

    let agreeablenessText = ""
    const agreeablenessTotal = 10
    section = agreeablenessTotal / 4
    if (agreeablenessScore <= section) {
        agreeablenessText = "Tu as tendance à être direct(e) et franc(che), parfois au risque de paraître clivant(e) ou critique."
    }
    else if (agreeablenessScore <= section * 2) {
        agreeablenessText = "Tu es généralement aimable et coopératif(ve), mais tu peux être ferme quand tes besoins ou tes valeurs sont en jeu."
    }
    else if (agreeablenessScore <= section * 3) {
        agreeablenessText = "Tu es naturellement empathique et coopératif(ve), toujours prêt(e) à aider les autres et à éviter les conflits. Tu places souvent les besoins des autres avant les tiens."
    }
    else {
        agreeablenessText = "Tu es extrêmement bienveillant(e) et altruiste, avec une forte tendance à mettre les autres à l'aise et à prendre soin d'eux, même si cela signifie négliger tes propres besoins."
    }

    let neuroticismText = ""
    const neuroticismTotal = 10
    section = neuroticismTotal / 4
    if (neuroticismScore <= section) {
        neuroticismText = "Tu es très calme et émotionnellement stable, rarement perturbé(e) ou inquiet(ète) même dans des situations stressantes."
    }
    else if (neuroticismScore <= section * 2) {
        neuroticismText = "Tu ressens des inquiétudes ou des émotions négatives de temps en temps, mais tu es généralement capable de les gérer efficacement."
    }
    else if (neuroticismScore <= section * 3) {
        neuroticismText = "Tu peux te sentir souvent anxieux(se) ou facilement perturbé(e) par des stress quotidiens. Tu es très réactif(ve) aux événements externes."
    }
    else {
        neuroticismText = "Tu es extrêmement sensible aux variations de ton environnement, souvent en proie à des émotions intenses et des réactions de stress. Gérer ces émotions est un défi constant pour toi."
    }

    return experienceText + " " + conscientiousnessText + " " + extroversionText + " " + agreeablenessText + " " + neuroticismText
}

function preventDefault(e){
    e.preventDefault();
}

export function disableScroll(){
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
}
export function enableScroll(){
    document.body.removeEventListener('touchmove', preventDefault);
}