import axios from "axios";

const IP = "https://api.keez.app"
const META_TOKEN = "EAAmLkVwQeq4BOZBddTvNbK2Wp0aZAXnNWpHnlKIASVT0aMcGUe0Ojt3flAmyxnqN8IhcMczYqKe7jU8VgmOCMg7zvwfzWUka4jtg5YDM1hGZC2SE3DUGmyo5GKETl7Gvm8boCjz7f30uKkOaxVkd7YEU73R2aoZBNc4fpqoFauDHVdqVW7XgEY2v3Dz1ah0CMwZDZD"
const PIXEL_ID = "2646810325523958"

export const checkEnrolment = async (email, setLoading, setIsErrorEmail, setErrorMessage) => {
    setLoading(true)
    try {
        await axios.get(`${IP}/api/enrolment/check?email=${email}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        setLoading(false)
        return true
    } catch (e) {
        setIsErrorEmail(true)
        setErrorMessage('Tu as déjà effectué ce test de compatibilité')
        setLoading(false)
        return false
    }
}

export const getEnrolmentData = async (setLoading) => {
    setLoading(true)
    try {
        const response = await axios.get(`${IP}/api/enrolment/data`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        setLoading(false)
        return response.data
    } catch (e) {
        setLoading(false)
    }
}

export const createEnrolment = async (user, attachmentStyle, personality) => {
    try {
        const data = {
            email: user.email,
            name: user.name,
            birthday: user.birthday,
            gender: user.gender,
            searchGender: user.searchGender,
            attachment: attachmentStyle,
            personality: personality
        }
        console.log('data', data)
        await axios.post(`${IP}/api/enrolment`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (e) {
        throw e
    }
}

export const getGenderString = (gender) => {
    switch (gender) {
        case "MALE":
            return "Un homme"
        case "FEMALE":
            return "Une femme"
        case "NON_BINARY":
            return "Une personne non-binaire"
        default:
            return "Un homme"
    }
}

export const getSearchGenderString = (gender) => {
    switch (gender) {
        case "MALE":
            return "Des hommes"
        case "FEMALE":
            return "Des femmes"
        case "NON_BINARY":
            return "Des personnes non-binaires"
        default:
            return "Tout le monde"
    }
}
