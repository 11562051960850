import "./PlainInput.css"
import {useEffect, useState} from "react";
import {disableScroll, enableScroll} from "../utilities";

const PlainInput = ({ value, setValue, placeholder, style = {}, isError = false }) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            disableScroll()
        }
        else {
            enableScroll()
        }
    }, [isOpen]);

    return (
        <input
            className="plain-input"
            placeholder={placeholder}
            value={value}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            onChange={(e) => setValue(e.target.value)}
            style={{
                width: '100%', borderRadius: 20,
                fontFamily: "Sequel", fontSize: 18, borderWidth: 1, borderColor: isError ? 'red' : 'white',
                paddingTop: 15, paddingBottom: 15, paddingLeft: 25, paddingRight: 25, marginTop: 15, marginBottom: 15,
                backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'white', borderStyle: 'solid', ...style
        }}
        />
    )
}

export default PlainInput;
