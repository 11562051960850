import './Info.css';
import Logo_text_dark from '../assets/Logo_text_dark.png';
import ArrowBack from '../assets/ArrowBack.svg';
import ArrowNext from '../assets/ArrowNext.svg';
import {LinearGradient} from "react-text-gradients";
import {useEffect, useState} from "react";
import DateInput from "../Component/DateInput";
import {CIcon} from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";
import {CAlert} from "@coreui/react";
import {getEnrolmentData} from "../api";
import List from "../Component/List";
import {Helmet} from "react-helmet";


const Info = ({ step, user, setUser, loading, setLoading, onNext, onBack, setLastStep, lastStep }) => {
    const [title, setTitle] = useState("Infos personnelles");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [genders, setGenders] = useState([]);
    const [searchGenders, setSearchGender] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);


    useEffect(() => {
        setTitle("Infos personnelles")
        setUser({...user, answers: []})
        getEnrolmentData(setLoading).then((res) => {
            console.log('res', res)
            if (res && res.genders && res.questions && res.searchGenders) {
                setGenders(res.genders)
                setQuestions(res.questions)
                setSearchGender(res.searchGenders)
            }
        })
    }, [])

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage(null)
                setIsError(false)
            }, 2500)
        }
    }, [errorMessage])

    useEffect(() => {
        if (step < 5) {
            if (step === 3) {
                const foundResponse = (user && user.gender) ? user.gender : null
                if (foundResponse) {
                    setSelectedItem(foundResponse)
                }
            }
            else if (step === 4) {
                const foundResponse = (user && user.searchGender) ? user.searchGender : null
                if (foundResponse) {
                    setSelectedItem(foundResponse)
                }
            }
            setTitle("Infos personnelles")
        }
        else {
            const foundResponse = (user && user.answers && user.answers.length > 0 && user.answers[step - 5]) ? user.answers[step - 5] : null
            if (foundResponse) {
                setSelectedItem(foundResponse)
            }
            const question = questions[step - 5]
            if (question) {
                const type = question.type
                if (type === "ATTACHMENT") {
                    setTitle("Style d’attachement")
                }
                else if (type === "LIFESTYLE") {
                    setTitle("Mode de vie")
                }
                else if (type === "HOBBIES") {
                    setTitle("Centres d’intérêt")
                }
                else if (type === "OPINION") {
                    setTitle("Opinion")
                }
                else {
                    setTitle("Personnalité")
                }
            }
        }
    }, [step])

    useEffect(() => {
        if (questions && questions.length > 0) {
            setLastStep(questions.length + 5)
        }
    }, [questions]);

    const isMajor = (date) => {
        const now = new Date();
        const birthDate = new Date(date);
        let age = now.getFullYear() - birthDate.getFullYear();
        const m = now.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
            age--;
        }

        return age >= 18;
    }

    const getPercentage = () => {
        return Math.round((step / lastStep) * 100)
    }


    const handleAnswerToQuestion = (answer) => {
        const answers = user.answers
        answers[step - 5] = {...answer, type: questions[step - 5].type}
        setUser({...user, answers})

        setSelectedItem(answer)
    }

    const handleBack = () => {
        onBack()
    }

    const handleNext = () => {
        setIsError(false)
        setErrorMessage(null)
        if (step === 2) {
            if (!user.birthday) {
                setIsError(true)
                setErrorMessage("Merci de renseigner votre date de naissance")
                return
            }
            else if (!isMajor(user.birthday)) {
                setIsError(true)
                setErrorMessage("Vous devez être majeur pour continuer")
                return
            }
        }
        else if (!selectedItem) {
            setIsError(true)
            setErrorMessage("Merci de sélectionner une valeur")
            return
        }

        setSelectedItem(null)
        onNext()
    }

    const displayButton = () => {
        return (
            <button
                className="next-button"
                onClick={() => handleNext()}
                disabled={step === 2 ? false : loading}
                style={{
                    borderRadius: 200,
                    borderColor: '#F1F2F4',
                    paddingTop: 12,
                    borderWidth: 0,
                    alignSelf: 'flex-end',
                    paddingBottom: 12,
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginTop: 15,
                    marginBottom: 15
                }}
            >
                {(step === 2 || !loading) &&
                    <div style={{
                        display: "flex",
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: "#E8DDFC",
                        fontSize: 16,
                        fontFamily: "Sequel Bold"
                    }}>
                        Suivant
                        <img className="arrow-next" src={ArrowNext} alt={"next"}
                             style={{width: 25, marginLeft: 8}}/>
                    </div>}
                {loading && step !== 2 && <div className="loader"></div>}
            </button>
        )
    }

    const displayQuestion = (question, type, list, onChange) => {
        return (
            <div style={{
                display: "flex",
                width: '100%',
                marginTop: 40,
                justifyContent: 'center',
                flexDirection: 'column'
            }}>
                <h2 className="question" style={{
                    fontFamily: "Sequel Bold",
                    color: "#131919",
                    marginBottom: 40,
                    textAlign: 'left'
                }}>{question}</h2>
                {type === "DATE" &&
                    <DateInput
                        isError={isError}
                        value={user.birthday}
                        placeholder={"DD/MM/YYYY"}
                        setValue={(value) => onChange(value)}
                    />}
                {type === "LIST" &&
                    <List
                        isGender={step === 3}
                        isSearchGender={step === 4}
                        list={list}
                        selectedItem={selectedItem}
                        onItemPress={(item) => {
                            setSelectedItem(item)
                            onChange(item)
                        }}
                    />}
                {displayButton()}
            </div>
        )
    }

    return (
        <div className="info-container">
            <div className="header-info" style={{display: "flex", flexDirection: 'row', width: '100vw', paddingTop: '2vh', paddingLeft: '5vw'}}>
                <img className="arrow-back" src={ArrowBack} alt={"back"} style={{width: 35, marginRight: '0', opacity: step === 2 ? 0 : 1}}
                     onClick={() => handleBack()}/>
                <img className="logo-text-info" src={Logo_text_dark} alt={"Logo with Keez"} style={{width: '15vh'}}/>
            </div>

            <div className="container-info">
                <h1 style={{fontFamily: "Sequel Bold", fontSize: 20, color: "#734DC2", marginBottom: 0}}>Test de
                    compatibilité</h1>
                <LinearGradient
                    className="info-title"
                    gradient={['to right', '#220856 ,#A47CF6']}
                    style={{color: "linear-gradient(145deg, #220856, #A47CF6)"}}
                >
                    {title}
                </LinearGradient>
                {step === 2 && displayQuestion("Quelle est ta date de naissance ?", "DATE", null, (value) => setUser({
                    ...user,
                    birthday: value
                }))}
                {step === 3 && displayQuestion("Tu es...", "LIST", genders, (value) => setUser({
                    ...user,
                    gender: value
                }))}
                {step === 4 && displayQuestion("Tu recherches...", "LIST", searchGenders, (value) => setUser({
                    ...user,
                    searchGender: value
                }))}
                {step > 4 && questions && questions[step - 5] &&
                    displayQuestion(questions[step - 5].question, "LIST", questions[step - 5].answers, (answer) => handleAnswerToQuestion(answer))}
            </div>
            <div className="completion-container">
                <p style={{color: "#9FA4A4", fontSize: 18, fontFamily: "Sequel", textAlign: 'left', marginBottom: 5}}>{getPercentage()}%</p>
                <div style={{backgroundColor: "#DFE1E1", width: '100%', height: 8, borderRadius: 10, marginTop: 0}}>
                    <div className="shadow-completion" style={{position: "absolute", left: 0, backgroundColor: "#734DC2", borderRadius: 10, height: 8, width: (getPercentage() + '%')}}/>
                </div>
            </div>
            <CAlert
                className="alert d-flex align-items-center"
                color="warning" dismissible visible={isError}
                onClose={() => {
                    setIsError(false)
                }}
            >
                <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                <div>{errorMessage}</div>
            </CAlert>
        </div>
    )
}

export default Info;
