import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import "./fonts/Sequel_Sans_Semi_Bold_Head.otf"
import "./fonts/Sequel_Sans_Medium_Obl_Disp.otf"
import "./fonts/Sequel_Sans_Light_Disp.otf"
import "./fonts/Sequel_Sans_Medium_Disp.otf"
import "./fonts/Sequel_Sans_Roman_Disp.otf"
import {useEffect, useState} from "react";
import Welcome from "./Screen/Welcome";
import { useSpring, animated } from '@react-spring/web'
import KeezSpeaker from "./Screen/KeezSpeaker";
import Info from "./Screen/Info";
import End from "./Screen/End";

const App = () => {
    const [step, setStep] = useState(0)
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [lastStep, setLastStep] = useState(10)

    const [backgroundColor, api] = useSpring(() => ({
        from: { background: "linear-gradient(145deg, #220856, #6D49BA)" },
    }))

    useEffect(() => {
        setStep(0)
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
        if (step === 0) {
            api.start({
                to: {
                    background: "linear-gradient(145deg, #220856, #6D49BA)"
                },
                config: { duration: 500 }
            })
        }
        else if (step === lastStep + 1) {
            api.start({
                to: {
                    background: "linear-gradient(145deg, #220856, #6D49BA)"
                },
                config: { duration: 500 }
            })
        }
        else {
            api.start({
                to: {
                    background: "linear-gradient(145deg, #FAFAFC, #E8DDFC)"
                },
                config: { duration: 0 }
            })
        }
    }, [step]);


  return (
    <animated.div className="App" style={{...backgroundColor}} >
        {step === 0 && <Welcome
            user={user} setUser={setUser}
            loading={loading} setLoading={setLoading}
            onNext={() => {
                api.start({
                    from: { background: "linear-gradient(145deg, #220856, #6D49BA)" },
                    to: {
                        background: "linear-gradient(145deg, #FAFAFC, #E8DDFC)"
                    },
                    config: { duration: 750 },
                    onResolve: () => {
                        setStep(1)
                    }
                })
            }}
        />}
        {step === 1 &&
        <KeezSpeaker
            texts={[
                "Bonjour " + user.name + ",",
                "Je suis Keez, ton assistant personnel pour trouver la personne qui te correspond le mieux",
                "Complète ce test de compatibilité pour que je puisse apprendre à mieux te connaître"
            ]}
            onFinished={() => {
                setStep(2)
            }}
        />}
        {step > 1 && step < lastStep &&
        <Info
            step={step}
            user={user}
            setUser={setUser}
            loading={loading}
            setLoading={setLoading}
            lastStep={lastStep}
            setLastStep={setLastStep}
            onNext={() => {
                setStep(step + 1)
            }}
            onBack={() => {
                if (step === 2) {
                    setStep(0)
                }
                else {
                    setStep(step - 1)
                }
            }}
        />}
        {step === lastStep &&
            <KeezSpeaker
                texts={[
                    "Merci " + user.name,
                    "J'ai bien reçu tes réponses."
                ]}
                onFinished={() => {
                    api.start({
                        from: { background: "linear-gradient(145deg, #FAFAFC, #E8DDFC)" },
                        to: {
                            background: "linear-gradient(145deg, #220856, #6D49BA)"

                        },
                        config: { duration: 750 },
                    })
                    setStep(lastStep + 1)
                }}
            />}
        {step === (lastStep + 1) &&
        <End user={user}/>}
    </animated.div>
  );
}

export default App;
