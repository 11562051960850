import "./Button.css"

const Button = ({ text, onPress, style = {}, loading = false }) => {

    return (
        <button
            className="button shadow-button"
            onClick={onPress}
            disabled={loading}
            style={{
                borderRadius: 200,
                fontFamily: "Sequel", fontSize: 18, borderWidth: 3, borderColor: '#F1F2F4',
                paddingTop: 15, paddingBottom: 15, marginTop: 15, marginBottom: 15,
                borderStyle: 'solid', ...style
        }}
        >
            {!loading && text}
            {loading && <div className="loader"></div>}
        </button>
    )
}

export default Button;
