import {useEffect, useState} from "react";
import './Welcome.css';
import logoText from '../assets/Logo_text.png';
import logo from '../assets/Logo.png';
import { LinearGradient } from 'react-text-gradients'
import PlainInput from "../Component/PlainInput";
import Button from "../Component/Button";
import { CAlert } from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cilWarning } from '@coreui/icons';
import {checkEnrolment} from "../api";

const Welcome = ({ user, setUser, loading, setLoading, onNext }) => {
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null)
    const [isError, setIsError] = useState(false)
    const [isErrorEmail, setIsErrorEmail] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        if (user) {
            if (user.name) {
                setName(user.name)
            }
            if (user.email) {
                setEmail(user.email)
            }
        }
    }, [user])

    useEffect(() => {
        if (name) {
            const nameWithUpper = name.charAt(0).toUpperCase() + name.slice(1)
            setUser({...user, name: nameWithUpper})
        }
    }, [name])

    useEffect(() => {
        if (email) {
            setUser({...user, email})
        }
    }, [email])

    useEffect(() => {
        if (errorMessage) {
            console.log('error message', errorMessage)
            setTimeout(() => {
                console.log('clear error message')
                setErrorMessage(null)
                setIsError(false)
                setIsErrorEmail(false)
            }, 5000)
        }
    }, [errorMessage])

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return (
        <div className="main">
            <img className="logo-text" src={logoText} alt={"Logo with Keez"}/>
            <img className="large-logo" src={logo} alt={"Logo"}/>
            <div className="container">
                <LinearGradient
                    className="title"
                    gradient={['to right', 'rgba(232, 221, 252, 0.6) ,#FFFFFF']}
                    style={{color: "linear-gradient(145deg, #220856, #6D49BA)"}}
                >
                    Réponds à notre test de compatibilité
                </LinearGradient>
                <p className="explain-welcome" style={{marginTop: 15}}>
                    Trouve la personnalité la plus compatible avec toi en répondant à notre test
                    de compatibilité. Cela ne prendra que quelques minutes.
                </p>

                <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '75%',
                    marginTop: '5vh'
                }}>
                    <PlainInput value={name} setValue={setName} placeholder={"Prénom"} isError={isError}/>
                    <PlainInput value={email} setValue={setEmail} placeholder={"Ton adresse email"}
                                isError={isError || isErrorEmail}/>
                    <Button
                        text={"Commencer"}
                        onPress={async () => {
                            if (!name) {
                                setIsError(true)
                                setErrorMessage("Veuillez remplir tous les champs")
                            } else if (!email || !validateEmail(email)) {
                                setIsErrorEmail(true)
                                setErrorMessage("Adresse email invalide")
                            } else {
                                setIsError(false)
                                setErrorMessage(null)
                                setIsErrorEmail(false)
                                if (await checkEnrolment(email, setLoading, setIsErrorEmail, setErrorMessage)) {
                                    onNext()
                                }
                            }
                        }}
                        style={loading ? {opacity: 0.75} : {}}
                        loading={loading}
                    />
                </div>
            </div>
            <CAlert
                className="alert d-flex align-items-center"
                color="warning" dismissible visible={isError || isErrorEmail}
                onClose={() => {
                    setIsError(false)
                    setIsErrorEmail(false)
                    setErrorMessage(null)
                }}
            >
                <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24}/>
                <div>{errorMessage}</div>
            </CAlert>
        </div>
    )
}

export default Welcome;
