import {useEffect, useRef, useState} from "react";
import './End.css';
import logoText from '../assets/Logo_text.png';
import logo from '../assets/Logo.png';
import { LinearGradient } from 'react-text-gradients'
import {computeAttachmentStyle, getAttachmentText, getBigFiveText, getPersonalityText} from "../utilities";
import {createEnrolment} from "../api";
import Button from "../Component/Button";
import {Helmet} from "react-helmet";

const End = ({ user }) => {
    const [attachmentStyle, setAttachmentStyle] = useState(null)
    const [experienceScore, setExperienceScore] = useState(0)
    const [conscientiousnessScore, setConscientiousnessScore] = useState(0)
    const [extroversionScore, setExtroversionScore] = useState(0)
    const [agreeablenessScore, setAgreeablenessScore] = useState(0)
    const [neuroticismScore, setNeuroticismScore] = useState(0)
    const [astroSign, setAstroSign] = useState(null)

    const isCreating = useRef(false)


    useEffect(() => {
        computeAttachmentStyle(user, setAttachmentStyle, setExperienceScore, setConscientiousnessScore, setExtroversionScore, setAgreeablenessScore, setNeuroticismScore)
        if (user && user.birthday) {
            const date = new Date(user.birthday)
            const month = date.getMonth() + 1
            const day = date.getDate()

            if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
                setAstroSign('aries')
            }
            else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
                setAstroSign('taurus')
            }
            else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
                setAstroSign('gemini')
            }
            else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
                setAstroSign('cancer')
            }
            else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
                setAstroSign('leo')
            }
            else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
                setAstroSign('virgo')
            }
            else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
                setAstroSign('libra')
            }
            else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
                setAstroSign('scorpio')
            }
            else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
                setAstroSign('sagittarius')
            }
            else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
                setAstroSign('capricorn')
            }
            else if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
                setAstroSign('aquarius')
            }
            else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
                setAstroSign('pisces')
            }
        }
    }, [user])

    useEffect(() => {
        if (attachmentStyle && isCreating.current === false && experienceScore && conscientiousnessScore && extroversionScore && agreeablenessScore && neuroticismScore) {
            isCreating.current = true
            const personality = {
                experienceScore,
                conscientiousnessScore,
                extroversionScore,
                agreeablenessScore,
                neuroticismScore
            }
            createEnrolment(user, attachmentStyle, JSON.stringify(personality)).then(() => {
                console.log('enrolment created')
                isCreating.current = false
            }).catch(e => {
                console.log('error creating enrolment', e)
                if (e && e.response && e.response.status === 403) {
                    alert('Tu as déjà effectué ce test de compatibilité')
                    return
                }
                alert('Tu as déjà effectué ce test de compatibilité...')
                isCreating.current = false
            })
        }
    }, [attachmentStyle, astroSign, experienceScore, conscientiousnessScore, extroversionScore, agreeablenessScore, neuroticismScore])


    return (
        <div className="main-end">
            <img className="logo-text" src={logoText} alt={"Logo with Keez"}/>
            <img className="large-logo" src={logo} alt={"Logo"}/>
            <div className="container-end">
                <LinearGradient
                    className="title"
                    gradient={['to right', 'rgba(232, 221, 252, 0.6) ,#FFFFFF']}
                    style={{color: "linear-gradient(145deg, #220856, #6D49BA)"}}
                >
                    Merci {user.name} pour tes réponses
                </LinearGradient>
                <p className="end-text" style={{marginTop: '5vh', marginBottom: '5vh'}}>
                    {getAttachmentText(attachmentStyle)}
                </p>
                {/*<p className="end-text" style={{marginBottom: '5vh'}}>*/}
                {/*    {getPersonalityText(astroSign)}*/}
                {/*</p>*/}
                <p className="end-text" style={{marginBottom: '5vh'}}>
                    {getBigFiveText(experienceScore, conscientiousnessScore, extroversionScore, agreeablenessScore, neuroticismScore)}
                </p>
                <p className="end-text-keez shadow-text" style={{marginBottom: 0}}>
                    Keez sera disponible le 16 octobre sur mobile.<br/>Keez te présentera les profils les
                    plus compatibles avec toi chaque jour. Défloute tes coups de coeur en approfondissant
                    ta relation avec eux.
                </p>
                <p className="end-text" style={{marginBottom: 40}}>
                    Pas de panique, Keez sera là pour t'accompagner dans ta recherche de l'amour.
                </p>
                <Button
                    text={"Découvrir Keez"}
                    onPress={() => {
                        window.location.href = "https://keez.app";
                    }}
                    style={{marginBottom: 50, zIndex: 999}}
                />
                <Helmet>
                    <script type="text/javascript">
                        fbq('track', 'End');
                    </script>
                </Helmet>

            </div>
        </div>
    )
}

export default End;
